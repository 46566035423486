import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"

// Theme
import Theme from "../components/layout/theme"

// Layout Components
import Layout from "../components/layout/index"
import SEO from "../components/seo/index"
import Container from "../components/container"

// Components
import Search from "../components/search/index"

export default ({ pageContext: { slug } }) => {
  const pageData = {
    title: `Search`,
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) =>
        modal ? (
          <ThemeProvider theme={Theme}>
            <Container
              sm={`
                            grid-row-gap:1rem;
                            padding-top:4rem;
                            padding-bottom:3rem;
                        `}
              lg={`
                            grid-row-gap:3rem;
                            padding-top:9rem;
                        `}
            >
              <Close
                to={closeTo}
                state={{
                  noScroll: true,
                }}
                aria-label={`Close Search Menu`}
              >
                <CloseIcon
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M15 5L5 15M5 5l10 10"
                    stroke="#435B8C"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </CloseIcon>
              </Close>
              <Search />
            </Container>
          </ThemeProvider>
        ) : (
          <Layout pageTitle={pageData.title} pathPrefix={slug}>
            <SEO 
              title={`${pageData.title} - Proof Blog`}
              noindex
            />
            <Container
              sm={`
                            grid-row-gap:1.5rem;
                            padding-top:1.5rem;
                        `}
              lg={`
                            grid-row-gap:3rem;
                            padding-top:3rem;
                        `}
            >
              <Title>{pageData.title}</Title>
              <Search />
            </Container>
          </Layout>
        )
      }
    </ModalRoutingContext.Consumer>
  )
}

// Styled Components
const Title = styled.h1`
  text-align: center;
`
const Close = styled(Link)`
  position: absolute;
  top: 1.5rem;
  right: 0;
  @media (min-width: 992px) {
    top: 3rem;
  }
`
const CloseIcon = styled.svg`
  width: 1.25rem;
  height: 1.25rem;
  @media (min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`
