import React, { useRef, useEffect } from "react"
import { connectInfiniteHits } from "react-instantsearch-dom"

import Item from "./item"

const InfiniteHits = ({ hits, hasMore, refine }) => {
  const sentinel = useRef(null)

  useEffect(() => {
    const onSentinelIntersection = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && hasMore) {
          refine()
        }
      })
    }

    const observer = new IntersectionObserver(onSentinelIntersection)

    observer.observe(sentinel.current)

    return () => {
      observer.disconnect()
    }
  }, [sentinel, hits, hasMore, refine])
  return (
    <>
      {hits.map(hit => (
        <Item
          key={hit.id}
          title={hit.title}
          excerpt={hit.excerpt}
          author={hit.author}
          category={hit.category}
          background={hit.background}
          link={hit.slug}
        />
      ))}
      <div ref={sentinel} />
    </>
  )
}

export default connectInfiniteHits(InfiniteHits)
