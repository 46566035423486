import React from "react"
import styled from "styled-components"
import { Facebook as Loader } from "react-content-loader"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom"

import Input from "./input"
import Hits from "./hits"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_SEARCH_KEY
)

const Loading = () => (
  <>
    <Loader />
    <Loader />
    <Loader />
  </>
)

const Results = connectStateResults(
  ({ isSearchStalled, searchState, searchResults, children }) =>
    isSearchStalled ? (
      <Loading />
    ) : searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <p>No results have been found for {searchState.query}.</p>
    )
)

export default () => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      stalledSearchDelay={1000}
    >
      <Configure hitsPerPage={5} />
      <Wrapper>
        <Input />
        <Results>
          <Hits />
        </Results>
      </Wrapper>
    </InstantSearch>
  )
}

// Styled Components
const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 2rem;
  @media (min-width: 992px) {
    grid-row-gap: 3rem;
  }
`
