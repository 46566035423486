import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { connectSearchBox } from "react-instantsearch-dom"

const Search = ({ currentRefinement, refine }) => {
  const searchRef = useRef()

  useEffect(() => {
    searchRef.current.focus()
  })

  return (
    <SearchInput
      ref={searchRef}
      type="text"
      value={currentRefinement}
      placeholder={`Search Proof...`}
      onChange={e => refine(e.currentTarget.value)}
    />
  )
}

export default connectSearchBox(Search)

const SearchInput = styled.input`
  -webkit-appearance: none;
  font-family: "GT Walsheim Pro";
  box-shadow: 0px 0px 0px 1px ${props => props.theme.color.primary["100"]};
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 0.5rem;
  transition: all 0.15s;
  border: none;
  outline: 0;
  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(30, 67, 255, 0.3);
  }
  @media (min-width: 992px) {
    font-size: 1.125rem;
    padding: 1rem;
  }
`
