import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Components
import Category from "../../components/post/category"
import Title from "../../components/post/title"
import Excerpt from "../../components/post/excerpt"
import Background from "../../components/post/background"
import Author from "../../components/post/author"

export default ({ title, excerpt, author, category, background, link }) => {
  return (
    <Article>
      <Content>
        <Title content={title} format={`search`} />
        <Excerpt format={`search`} content={excerpt} />
        <Footer>
          <Category name={category.name} slug={category.slug} />
          <Author type={`neutral`} hideAvatar name={author.name} slug={author.slug} />
        </Footer>
      </Content>
      <Background image={background} title={title} />
      <PostLink to={`/${link}`} aria-label={`Read more about ${title}`} />
    </Article>
  )
}

// Styled Components
const Article = styled.article`
  display: grid;
  grid-column-gap: 0.75rem;
  align-items: start;
  grid-template-columns: minmax(0, 1fr) minmax(0, 7.5rem);
  position: relative;
  @media (min-width: 992px) {
    grid-column-gap: 3rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 20.875rem);
  }
`
const PostLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const Content = styled.div`
  display: grid;
  grid-row-gap: 1rem;
`
const Footer = styled.div`
  display: flex;
  align-items: center;
`
